const imageBaseUrl = process.env.REACT_APP_IMAGE_URL
const m1 = imageBaseUrl + 'specialization/1.png'
const m2 = imageBaseUrl + 'specialization/2.png'
const m3 = imageBaseUrl + 'specialization/3.png'
const m4 = imageBaseUrl + 'specialization/4.png'
const m5 = imageBaseUrl + 'specialization/5.png'
const m6 = imageBaseUrl + 'specialization/6.png'
const m7 = imageBaseUrl + 'specialization/7.png'
const m8 = imageBaseUrl + 'specialization/8.png'
const m9 = imageBaseUrl + 'specialization/9.png'
const m10 = imageBaseUrl + 'specialization/10.png'

const EngineeringSpecialization = [
    {
        id: 1,
        Image: m1,
        title: "Civil"
    },
    {
        id: 2,
        Image: m2,
        title: "Mechanical"
    },
    {
        id: 3,
        Image: m3,
        title: "Electrical"
    },
    {
        id: 4,
        Image: m4,
        title: "Computer"
    },
    {
        id: 5,
        Image: m5,
        title: "Chemical"
    },
    // {
    //     id: 6,
    //     Image: m6,
    //     title: "Biomedical"
    // },
    {
        id: 7,
        Image: m7,
        title: "Environmental"
    },
    {
        id: 8,
        Image: m8,
        title: "Industrial"
    },
    {
        id: 9,
        Image: m9,
        title: "Petroleum"
    },
    {
        id: 10,
        Image: m10,
        title: "Materials"
    }
];
const CSITSpecialization = [
    {
        id: 1,
        Image: m1,
        title: "Artificial Intelligence (AI)"
    },
    {
        id: 2,
        Image: m2,
        title: "Data Science and Big Data Analytics"
    },
    {
        id: 3,
        Image: m3,
        title: "Cybersecurity"
    },
    {
        id: 4,
        Image: m4,
        title: "Software Engineering"
    },
    {
        id: 5,
        Image: m5,
        title: "Computer Networks and Distributed Systems"
    },
    {
        id: 6,
        Image: m6,
        title: "Human-Computer Interaction (HCI)"
    },
    {
        id: 7,
        Image: m7,
        title: "Bioinformatics and Computational Biology"
    },
    {
        id: 8,
        Image: m8,
        title: "Cloud Computing and DevOps"
    },

];
const HSMSpecialization = [
    {
        id: 1,
        Image: m1,
        title: "Medicine"
    },
    {
        id: 2,
        Image: m2,
        title: "Nursing"
    },
    {
        id: 3,
        Image: m3,
        title: "Dentistry"
    },
    // {
    //     id: 4,
    //     Image: m4,
    //     title: "Pharmacy"
    // },
    // {
    //     id: 5,
    //     Image: m5,
    //     title: "Public Health"
    // },

];
const SocialSpecialization = [
    {
        id: 1,
        Image: m1,
        title: "Sociology"
    },
    {
        id: 2,
        Image: m2,
        title: "Psychology"
    },
    // {
    //     id: 3,
    //     Image: m3,
    //     title: "Anthropology"
    // },
    {
        id: 4,
        Image: m4,
        title: "Political Science"
    },
    {
        id: 5,
        Image: m5,
        title: "Economics"
    },
    {
        id: 6,
        Image: m6,
        title: "Geography"
    },
    {
        id: 7,
        Image: m7,
        title: "History"
    },

];
const BusinessSpecialization = [
    {
        id: 1,
        Image: m1,
        title: "Finance"
    },
    {
        id: 2,
        Image: m2,
        title: "Marketing"
    },
    {
        id: 3,
        Image: m3,
        title: "Entrepreneurship"
    },
    {
        id: 4,
        Image: m4,
        title: "Human Resource Management"
    },
    {
        id: 5,
        Image: m5,
        title: "International Business"
    },
    {
        id: 6,
        Image: m6,
        title: "Supply Chain Management"
    },
    {
        id: 7,
        Image: m7,
        title: "Information Technology "
    },
    {
        id: 8,
        Image: m8,
        title: "Strategic Management"
    },
    {
        id: 9,
        Image: m9,
        title: "Operations Management"
    },
    // {
    //     id: 10,
    //     Image: m10,
    //     title: "Hospitality Management"
    // }
];
const PlsSpecialization = [
    {
        id: 1,
        Image: m1,
        title: "Physics "
    },
    {
        id: 2,
        Image: m2,
        title: "Chemistry"
    },
    {
        id: 3,
        Image: m3,
        title: "Biology"
    },
    {
        id: 4,
        Image: m4,
        title: "Environmental Science"
    },
    {
        id: 5,
        Image: m5,
        title: "Geology"
    },
    {
        id: 6,
        Image: m6,
        title: "Astronomy"
    },
    {
        id: 7,
        Image: m7,
        title: "Biotechnology"
    },
    {
        id: 8,
        Image: m8,
        title: "Neuroscience"
    },
    {
        id: 9,
        Image: m9,
        title: "Materials Science"
    },
    // {
    //     id: 10,
    //     Image: m10,
    //     title: "Bioinformatics"
    // }
];
const FaaSpecialization = [
    {
        id: 1,
        Image: m1,
        title: "Painting"
    },
    {
        id: 2,
        Image: m2,
        title: "Sculpture"
    },
    {
        id: 3,
        Image: m3,
        title: "Graphic Design"
    },
    {
        id: 4,
        Image: m4,
        title: "Photography"
    },
    {
        id: 5,
        Image: m5,
        title: "Illustration"
    },
    {
        id: 6,
        Image: m6,
        title: "Multimedia Art"
    },
    {
        id: 7,
        Image: m7,
        title: "Textile Design"
    },
    {
        id: 8,
        Image: m8,
        title: "Animation"
    },
    {
        id: 9,
        Image: m9,
        title: "Printmaking"
    },

];
const CJSpecialization = [
    {
        id: 1,
        Image: m1,
        title: "Journalism"
    },
    {
        id: 2,
        Image: m2,
        title: "Broadcast Journalism."
    },
    {
        id: 3,
        Image: m3,
        title: "Digital Journalism"
    },
    {
        id: 4,
        Image: m4,
        title: "Public Relations"
    },
    {
        id: 5,
        Image: m5,
        title: "Advertising"
    },
    {
        id: 6,
        Image: m6,
        title: "Media Studies"
    },
    {
        id: 7,
        Image: m7,
        title: "Strategic Communication"
    },
    {
        id: 8,
        Image: m8,
        title: "International Communication"
    },
    {
        id: 9,
        Image: m9,
        title: "Visual Communication"
    },
    // {
    //     id: 10,
    //     Image: m10,
    //     title: "Health Communication"
    // }
];
const DesigningSpecialization = [
    {
        id: 1,
        Image: m1,
        title: "Graphic"
    },
    {
        id: 2,
        Image: m2,
        title: "Industrial"
    },
    {
        id: 3,
        Image: m3,
        title: "Fashion"
    },
    {
        id: 4,
        Image: m4,
        title: "Interior"
    },
    {
        id: 5,
        Image: m5,
        title: "User Experience"
    },
    {
        id: 6,
        Image: m6,
        title: "User Interface"
    },
    {
        id: 7,
        Image: m7,
        title: "Web"
    },
    {
        id: 8,
        Image: m8,
        title: "Animation and Motion"
    },
    {
        id: 9,
        Image: m9,
        title: "Product"
    },
    // {
    //     id: 10,
    //     Image: m10,
    //     title: "Sustainable"
    // }
];

export { EngineeringSpecialization, CSITSpecialization, HSMSpecialization, SocialSpecialization, BusinessSpecialization, PlsSpecialization, FaaSpecialization, CJSpecialization, DesigningSpecialization }