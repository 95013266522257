const imageBaseUrl = process.env.REACT_APP_IMAGE_URL

const eligibility1  = imageBaseUrl + "eligibility1.png";
const eligibility2  = imageBaseUrl + "eligibilityImg_2.png";
const eligibility3  = imageBaseUrl + "eligibilityImg_3.png";
const eligibility4  = imageBaseUrl + "eligibilityImg_4.png";
const eligibility5  = imageBaseUrl + "eligibilityImg_5.png";
const eligibility6  = imageBaseUrl + "eligibilityImg_6.png";
const eligibility7  = imageBaseUrl + "eligibilityImg_7.png";
const eligibility8  = imageBaseUrl + "eligibilityImg_8.png";
const eligibility9  = imageBaseUrl + "eligibilityImg9.png";
const eligibility10  = imageBaseUrl + "eligibilityImg10.png";

const immigrationEligibilityData = [
  {
    title: "Age",
    items: [
      "Candidates must be at least 17 years old at the time of admission.",
    ],
    Image: eligibility1,
  },
  {
    title: "Visa Category",
    items: [
      "Candidates must have completed 10+2 or equivalent examinations with Physics, Chemistry, Biology.",
    ],
    Image: eligibility2,
  },
  {
    title: "English Language Proficiency",
    items: [
      "Candidates must have completed 10+2 or equivalent examinations with Physics, Chemistry, Biology.",
    ],
    Image: eligibility3,
  },
  {
    title: "Skill Assessment",
    items: [
      "Candidates must have scored the minimum aggregate marks required in the qualifying examination which is 50% for the general category, 45% for OBC/SC/ST, and 40% for PWD.",
    ],
    Image: eligibility4,
  },
  {
    title: "Education Qualification",
    items: [
      "Candidates need to qualify for national or state-level medical entrance exams like NEET (National Eligibility cum Entrance Test) for admission to MBBS programs in India",
    ],
    Image: eligibility5,
  },
  {
    title: "Work Experience",
    items: [
      "Candidates need to qualify for national or state-level medical entrance exams like NEET (National Eligibility cum Entrance Test) for admission to MBBS programs in India",
    ],
    Image: eligibility6,
  },
  {
    title: "Occupation In Demand",
    items: [
      "Candidates need to qualify for national or state-level medical entrance exams like NEET (National Eligibility cum Entrance Test) for admission to MBBS programs in India",
    ],
    Image: eligibility7,
  },
  {
    title: "Financial Requirement",
    items: [
      "Candidates need to qualify for national or state-level medical entrance exams like NEET (National Eligibility cum Entrance Test) for admission to MBBS programs in India",
    ],
    Image: eligibility8,
  },
  {
    title: "Health and Character Checks",
    items: [
      "Candidates need to qualify for national or state-level medical entrance exams like NEET (National Eligibility cum Entrance Test) for admission to MBBS programs in India",
    ],
    Image: eligibility9,
  },
  {
    title: "Support or Sponsorship",
    items: [
      "Candidates need to qualify for national or state-level medical entrance exams like NEET (National Eligibility cum Entrance Test) for admission to MBBS programs in India",
    ],
    Image: eligibility10,
  },
  
];

export default immigrationEligibilityData;
