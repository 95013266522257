const imageBaseUrl = process.env.REACT_APP_IMAGE_URL

const eligibility1 =imageBaseUrl + "eligibility1.png";
const eligibility2 =imageBaseUrl + "eligibility2.png";
const eligibility3 =imageBaseUrl + "eligibility3.png";
const eligibility4 =imageBaseUrl + "eligibility4.png";
const eligibility5 =imageBaseUrl + "eligibility5.png";
const eligibility6 =imageBaseUrl + "eligibility6.png";
const eligibilityAbroadData = [
  {
    title: "Age",
    items: [
      "Similar to MBBS in India, candidates must meet the minimum age requirement of 17 years and above as specified by the medical universities abroad.",
    ],
    Image: eligibility1,
  },
  {
    title: "Educational Qualifications",
    items: [
      "Candidates must have completed their high school education (equivalent to 10+2 in India) with Physics, Chemistry, Biology, and English as core subjects.",
    ],
    Image: eligibility2,
  },
  {
    title: "Minimum Marks",
    items: [
      "Many medical universities abroad require candidates to have scored a certain minimum percentage in their high school or equivalent examination.",
    ],
    Image: eligibility3,
  },
  {
    title: "Entrance Exams",
    items: [
      "Some countries may require candidates to qualify for specific entrance exams conducted by the respective medical universities or medical councils of the country.",
    ],
    Image: eligibility4,
  },
  {
    title: "Language Proficiency",
    items: [
      "For countries where the medium of instruction is English, candidates may need to demonstrate proficiency in English through standardised tests like IELTS or TOEFL.",
    ],
    Image: eligibility5,
  },
  {
    title: "Visa Requirements",
    items: [
      "Candidates need to fulfil the visa requirements of the country they intend to study in, including obtaining a student visa.",
    ],
    Image: eligibility6,
  },
];

export default eligibilityAbroadData;
