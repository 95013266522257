
import FormComponent from "src/Component/form-component/Form";
import { Box, Container, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material'
import ButtonComponent from "src/Component/content-component/ButtonComponent";
import { useState } from "react";
import MainHeader from "src/Component/content-component/MainHeader";
import { Icon } from "@iconify/react";
import CustomCard from "src/Component/card-components/custom-cardFormController";

const imageBaseUrl = process.env.REACT_APP_IMAGE_URL
const TestImage = imageBaseUrl + "testprep.png";

const TestMainBanner = () => {
    const [openDialog, setOpenDialog] = useState(false);
    console.log(openDialog, 'sddaddsd')

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    return (
        <>
            <Box sx={{
                width: '100%',
                backgroundColor: '#effdff',
                paddingTop: "30px",
                display: "flex", justifyContent: "center"
            }}>
                <Container >
                    <Box sx={{
                        display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", textAlign: "center"
                    }}>
                        <MainHeader
                            color="rgba(0, 0, 0, 0.7)"
                            textAlign="center"
                            content={"Ace your exams with our comprehensive test preparation courses "} />
                        <Box
                            sx={
                                {
                                    display: "flex",
                                    justifyContent: "center",
                                    margin: "20px 0"
                                }
                            }
                        >
                            <ButtonComponent
                                fontSize="18px"
                                width="300px"
                                padding="10px 30px"
                                text="Book A Free Demo Class"
                                onClick={handleOpenDialog}
                            />
                        </Box>
                        <Box sx={{ width: { md: "700px", sm: "500px" }, }}>
                            <img src={TestImage} alt='TestPrepImage' width={"100%"} />
                        </Box>
                    </Box>

                    {/* <SliderComponent
                 images={[doc1, doc1, doc1]}
              /> */}
                </Container>
            </Box>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                {/* <DialogTitle>Register Now</DialogTitle> */}
                <DialogTitle>
                                       <IconButton aria-label="close" onClick={handleCloseDialog} sx={{ position: 'absolute', right: 8, top: 8, color: 'inherit' }}>
                        <Icon icon="bitcoin-icons:cross-outline" />
                    </IconButton>
                </DialogTitle>
                {/* <FormComponent handleCloseDialog=
                    {handleCloseDialog} setOpenDialog={setOpenDialog} /> */}
 <DialogContent  >
                    <CustomCard />
                </DialogContent>
            </Dialog>
        </>
    )
};
export default TestMainBanner;
