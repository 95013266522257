import React from 'react';
import { Box, Grid, Typography } from '@mui/material';

const imageBaseUrl = process.env.REACT_APP_IMAGE_URL

const i1 = imageBaseUrl + 'universityinfo/1.png'
const i2 = imageBaseUrl + 'universityinfo/2.png'
const i3 = imageBaseUrl + 'universityinfo/3.png'
const i4 = imageBaseUrl + 'universityinfo/4.png'
const i5 = imageBaseUrl + 'universityinfo/5.png'
const i6 = imageBaseUrl + 'universityinfo/6.png'
const i7 = imageBaseUrl + 'universityinfo/7.png'

const UniversityInfo = ({ data }) => {

    const handleReadMoreClick = () => {

        window.open(data.url, '_blank');

    };
    return (
        <Box sx={{ flexGrow: 1, p: 3, padding: "40px 0px" }}>
            <Grid container spacing={2} columns={14} justifyContent="center">
                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <Box textAlign="center" sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", borderRight: { md: "2px solid #c1c1c1", xs: "none" }, height: "150px" }}>
                        <Box height={"50px"}>

                            <img src={i1} alt='' />
                        </Box>
                        <Box height={"80px"}>
                            <Typography variant="h6" fontSize={"18px"} >Name</Typography>
                            <Typography variant="body1" sx={{ width: "150px", textAlign: "center" }}>{data?.name}</Typography>
                        </Box>

                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <Box textAlign="center" sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", borderRight: { md: "2px solid #c1c1c1", xs: "none" }, height: "150px" }}>
                        <Box height={"50px"}>

                            <img src={i2} alt='' />
                        </Box>
                        <Box height={"80px"}
                        >

                            <Typography variant="h6" fontSize={"18px"}>Location</Typography>
                            <Typography variant="body1" sx={{ width: "150px", textAlign: "center" }}>{data?.location}</Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <Box textAlign="center" sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", borderRight: { md: "2px solid #c1c1c1", xs: "none" }, height: "150px" }}>
                        <Box height={"50px"}>

                            <img src={i3} alt='' />
                        </Box>
                        <Box height={"80px"}>

                            <Typography variant="h6" fontSize={"18px"}>Type</Typography>
                            <Typography variant="body1" sx={{ width: "150px", textAlign: "center" }}>{data?.type}</Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <Box textAlign="center" sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", borderRight: { md: "2px solid #c1c1c1", xs: "none" }, height: "150px" }}>
                        <Box height={"50px"}>

                            <img src={i4} alt='' />
                        </Box>
                        <Box height={"80px"}>

                            <Typography variant="h6" fontSize={"18px"}>Total Enrollment</Typography>
                            <Typography variant="body1" sx={{ width: "150px", textAlign: "center" }}>{data?.enrollment}</Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <Box textAlign="center" sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", borderRight: { md: "2px solid #c1c1c1", xs: "none" }, height: "150px" }}>
                        <Box height={"50px"}>

                            <img src={i5} alt='' />
                        </Box>
                        <Box height={"80px"}>

                            <Typography variant="h6" fontSize={"18px"}>Indian Students</Typography>
                            <Typography variant="body1" sx={{ width: "150px", textAlign: "center" }}>{data?.indian}</Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <Box textAlign="center" sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", borderRight: { md: "2px solid #c1c1c1", xs: "none" }, height: "150px" }}>
                        <Box height={"50px"}>

                            <img src={i6} alt='' />
                        </Box>
                        <Box height={"80px"}>

                            <Typography variant="h6" fontSize={"18px"}>Total Students</Typography>
                            <Typography variant="body1" sx={{ width: "150px", textAlign: "center" }}>{data?.students}</Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <Box textAlign="center" sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", cursor: "pointer", height: "150px" }} onClick={handleReadMoreClick}>
                        <Box height={"50px"}>

                            <img src={i7} alt='' />
                        </Box>
                        <Box height={"80px"}>

                            <Typography variant="h6" fontSize={"18px"}>Website</Typography>
                            <Typography variant="body1" sx={{ width: "150px", textAlign: "center" }}>{data?.name}</Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default UniversityInfo;
