const imageBaseUrl = process.env.REACT_APP_IMAGE_URL

const k1 = imageBaseUrl + "universityKnow/k1.png";
const k2 = imageBaseUrl + "universityKnow/k2.png";
const k3 = imageBaseUrl + "universityKnow/k3.png";
// const k4 = imageBaseUrl + 'universityKnow/k4.png'
const k5 = imageBaseUrl + "universityKnow/k5.png";
const k6 = imageBaseUrl + "universityKnow/k6.png";

const CaliforniaKnowMore = [
  {
    title: "Campus life",
    img: k1,
    url: "https://www.berkeley.edu/campus-life/"
  },
  {
    title: "How to Apply",
    img: k2,
    url: "https://www.berkeley.edu/admissions/"
  },

  {
    title: "Alumni",
    img: k3,
    url: "https://career.berkeley.edu/alumni/"
  },

  {
    title: "Placements Statistics",
    img: k5,
    url: "https://socialwelfare.berkeley.edu/academics/practicum-placement"
  },
  {
    title: "Brochure",
    img: k6,
    url: "https://www.be.berkeley.edu/wp-content/uploads/Cal-Facts-2023-brochure.pdf"
  }
];
const WisconsinKnowMore = [
  {
    title: "Campus life",
    img: k1,
    url: "https://www.wisc.edu/student-life/"
  },
  {
    title: "How to Apply",
    img: k2,
    url: "https://admissions.wisc.edu/how-do-i-apply-to-uw-madison/"
  },

  {
    title: "Alumni",
    img: k3,
    url: "https://uwalumni.com/"
  },

  {
    title: "Placements Statistics",
    img: k5,
    url: "https://econ.wisc.edu/doctoral/career-placement/"
  },
  {
    title: "Brochure",
    img: k6,
    url: "https://wiseli.wisc.edu/resources/guidebooks-brochures/"
  }
];
const YeshivaKnowMore = [
  {
    title: "Campus life",
    img: k1,
    url: "https://www.yu.edu/student-life"
  },
  {
    title: "How to Apply",
    img: k2,
    url: "https://yu.elluciancrmrecruit.com/Apply/Account"
  },

  {
    title: "Alumni",
    img: k3,
    url: "https://www.yu.edu/alumni"
  },

  {
    title: "Placements Statistics",
    img: k5,
    url: "https://www.yu.edu/sgc/caps/"
  },
  {
    title: "Brochure",
    img: k6,
    url: "https://www.yu.edu/sites/default/files/inline-files"
  }
];
const UtahKnowMore = [
  {
    title: "Campus life",
    img: k1,
    url: "https://www.suu.edu/life/"
  },
  {
    title: "How to Apply",
    img: k2,
    url: "https://www.suu.edu/admissions"
  },

  {
    title: "Alumni",
    img: k3,
    url: "https://www.suu.edu/alumni/"
  },

  {
    title: "Placements Statistics",
    img: k5,
    url: "https://www.suu.edu/hss/mis/job-placement.html"
  },
  {
    title: "Brochure",
    img: k6,
    url: "https://www.suu.edu/international/downloads.html"
  }
];
const ColoradoKnowMore = [
  {
    title: "Campus life",
    img: k1,
    url: "https://admissions.colostate.edu/about-csu/campus-life/"
  },
  {
    title: "How to Apply",
    img: k2,
    url: "https://admissions.colostate.edu/"
  },

  {
    title: "Alumni",
    img: k3,
    url: "https://alumni.colostate.edu/"
  },

  {
    title: "Placements Statistics",
    img: k5,
    url: "https://career.colostate.edu/career-outcomes-and-data/"
  },
  {
    title: "Brochure",
    img: k6,
    url: "https://international.colostate.edu/wp-content/uploads/sites/50/2022/08/CSU-International-Brochure-23.pdf"
  }
];

const IllinoisKnowMore = [
  {
    title: "Campus life",
    img: k1,
    url: "https://admissions.illinois.edu/about-csu/campus-life/"
  },
  {
    title: "How to Apply",
    img: k2,
    url: "https://admissions.illinois.edu/"
  },

  {
    title: "Alumni",
    img: k3,
    url: "https://alumni.illinois.edu/"
  },

  {
    title: "Placements Statistics",
    img: k5,
    url: "https://career.illinois.edu/career-outcomes-and-data/"
  },
  {
    title: "Brochure",
    img: k6,
    url: "https://international.illinois.edu/wp-content/uploads/sites/50/2022/08/CSU-International-Brochure-23.pdf"
  }
];

const StanfordKnowMore = [
  {
    title: "Campus life",
    img: k1,
    url: "https://admissions.Stanford.edu/about-csu/campus-life/"
  },
  {
    title: "How to Apply",
    img: k2,
    url: "https://admissions.Stanford.edu/"
  },

  {
    title: "Alumni",
    img: k3,
    url: "https://alumni.Stanford.edu/"
  },

  {
    title: "Placements Statistics",
    img: k5,
    url: "https://career.Stanford.edu/career-outcomes-and-data/"
  },
  {
    title: "Brochure",
    img: k6,
    url: "https://international.Stanford.edu/wp-content/uploads/sites/50/2022/08/CSU-International-Brochure-23.pdf"
  }
];

const PennsylvaniaKnowMore = [
  {
    title: "Campus life",
    img: k1,
    url: "https://admissions.pennsylvania.edu/about-csu/campus-life/"
  },
  {
    title: "How to Apply",
    img: k2,
    url: "https://admissions.pennsylvania.edu/"
  },

  {
    title: "Alumni",
    img: k3,
    url: "https://alumni.pennsylvania.edu/"
  },

  {
    title: "Placements Statistics",
    img: k5,
    url: "https://career.pennsylvania.edu/career-outcomes-and-data/"
  },
  {
    title: "Brochure",
    img: k6,
    url: "https://international.pennsylvania.edu/wp-content/uploads/sites/50/2022/08/CSU-International-Brochure-23.pdf"
  }
];

const ChicagoKnowMore = [
  {
    title: "Campus life",
    img: k1,
    url: "https://admissions.chicago.edu/about-csu/campus-life/"
  },
  {
    title: "How to Apply",
    img: k2,
    url: "https://admissions.chicago.edu/"
  },

  {
    title: "Alumni",
    img: k3,
    url: "https://alumni.chicago.edu/"
  },

  {
    title: "Placements Statistics",
    img: k5,
    url: "https://career.chicago.edu/career-outcomes-and-data/"
  },
  {
    title: "Brochure",
    img: k6,
    url: "https://international.chicago.edu/wp-content/uploads/sites/50/2022/08/CSU-International-Brochure-23.pdf"
  }
];

const MITKnowMore = [
  {
    title: "Campus life",
    img: k1,
    url: "https://admissions.mit.edu/about-csu/campus-life/"
  },
  {
    title: "How to Apply",
    img: k2,
    url: "https://admissions.mit.edu/"
  },

  {
    title: "Alumni",
    img: k3,
    url: "https://alumni.mit.edu/"
  },

  {
    title: "Placements Statistics",
    img: k5,
    url: "https://career.mit.edu/career-outcomes-and-data/"
  },
  {
    title: "Brochure",
    img: k6,
    url: "https://international.mit.edu/wp-content/uploads/sites/50/2022/08/CSU-International-Brochure-23.pdf"
  }
];

//UK
const BristolKnowMore = [
  {
    title: "Campus life",
    img: k1,
    url: "https://www.bristol.ac.uk/study/undergraduate/student-life/"
  }, 
  {
    title: "How to Apply",
    img: k2,
    url: "https://www.bristol.ac.uk/study/undergraduate/apply/"
  },
  {
    title: "Alumni",
    img: k3,
    url: "https://www.bristol.ac.uk/alumni/"
  },
  {
    title: "Placements Statistics",
    img: k5,
    url: "https://theatrecollection.blogs.bristol.ac.uk/category/student-placement-at-the-theatre-collection/"
  },
  {
    title: "Brochure",
    img: k6,
    url: "https://www.bristol.ac.uk/media-library/sites/international/documents/International%20Guide%202023-24.pdf"
  }
];
const LeedsKnowMore = [
  {
    title: "Campus life",
    img: k1,
    url: "https://www.leeds.ac.uk/around-campus"
  },
  {
    title: "How to Apply",
    img: k2,
    url: "https://www.leeds.ac.uk/access-to-leeds/doc/atl-apply"
  },
  {
    title: "Alumni",
    img: k3,
    url: "https://www.leeds.ac.uk/alumni"
  },
  {
    title: "Placements Statistics",
    img: k5,
    url: "https://business.leeds.ac.uk/undergraduate/doc/work-placement"
  },
  {
    title: "Brochure",
    img: k6,
    url: "https://www.leeds.ac.uk/downloads/download/709/liss-brochure"
  }
];
const GlasgowKnowMore = [
  {
    title: "Campus life",
    img: k1,
    url: "https://www.gla.ac.uk/study/studentlife/ourcampuses/"
  },
  {
    title: "How to Apply",
    img: k2,
    url: "https://www.gla.ac.uk/study/search/?query=how%20to%20apply&level=ugentry&mode=&subject="
  },
  {
    title: "Alumni",
    img: k3,
    url: "https://www.gla.ac.uk/alumni/"
  },
  {
    title: "Placements Statistics",
    img: k5,
    url: "https://www.gla.ac.uk/postgraduate/erasmusmundus/ceeres/programmedetailsandstructure/placementsandinternships/"
  },
  {
    title: "Brochure",
    img: k6,
    url: "https://www.gla.ac.uk/prospectuses/"
  }
];
const BirminghamKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace k1 with the actual image variable
    url: "https://www.birmingham.ac.uk/study/student-experience/campus-life"
  },
  {
    title: "How to Apply",
    img: k2, // Replace k2 with the actual image variable
    url: "https://www.birmingham.ac.uk/study/postgraduate/taught/apply"
  },
  {
    title: "Alumni",
    img: k3, // Replace k3 with the actual image variable
    url: "https://www.birmingham.ac.uk/alumni"
  },

  {
    title: "Placements Statistics",
    img: k5, // Replace k5 with the actual image variable
    url: "https://www.birmingham.ac.uk/schools/business/corporate/placements-internships"
  },
  {
    title: "Brochure",
    img: k6, // Replace k6 with the actual image variable
    url: "https://www.birmingham.ac.uk/study/undergraduate/prospectus"
  }
];
const ImperialKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace k1 with the actual image variable
    url: "https://www.imperial.ac.uk/study/student-life/"
  },
  {
    title: "How to Apply",
    img: k2, // Replace k2 with the actual image variable
    url: "https://www.imperial.ac.uk/study/apply/"
  },
  {
    title: "Alumni",
    img: k3, // Replace k3 with the actual image variable
    url: "https://www.imperial.ac.uk/alumni/"
  },

  {
    title: "Placements Statistics",
    img: k5, // Replace k5 with the actual image variable
    url: "https://www.imperial.ac.uk/careers/jobs-and-experience/work-experience/industrial-placements-/"
  },
  {
    title: "Brochure",
    img: k6, // Replace k6 with the actual image variable
    url: "https://www.imperial.ac.uk/business-school/programmes/brochures/"
  }
];

const QueenMaryKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace k1 with the actual image variable
    url: "https://www.qmul.ac.uk/study/student-life/"
  },
  {
    title: "How to Apply",
    img: k2, // Replace k2 with the actual image variable
    url: "https://www.qmul.ac.uk/study/apply/"
  },
  {
    title: "Alumni",
    img: k3, // Replace k3 with the actual image variable
    url: "https://www.qmul.ac.uk/alumni/"
  },

  {
    title: "Placements Statistics",
    img: k5, // Replace k5 with the actual image variable
    url: "https://www.qmul.ac.uk/careers/jobs-and-experience/work-experience/industrial-placements-/"
  },
  {
    title: "Brochure",
    img: k6, // Replace k6 with the actual image variable
    url: "https://www.qmul.ac.uk/business-school/programmes/brochures/"
  }
];

const LiverpoolKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace k1 with the actual image variable
    url: "https://www.liverpool.ac.uk/study/student-life/"
  },
  {
    title: "How to Apply",
    img: k2, // Replace k2 with the actual image variable
    url: "https://www.liverpool.ac.uk/study/apply/"
  },
  {
    title: "Alumni",
    img: k3, // Replace k3 with the actual image variable
    url: "https://www.liverpool.ac.uk/alumni/"
  },

  {
    title: "Placements Statistics",
    img: k5, // Replace k5 with the actual image variable
    url: "https://www.liverpool.ac.uk/careers/jobs-and-experience/work-experience/industrial-placements-/"
  },
  {
    title: "Brochure",
    img: k6, // Replace k6 with the actual image variable
    url: "https://www.liverpool.ac.uk/business-school/programmes/brochures/"
  }
];

const EdinburghKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace k1 with the actual image variable
    url: "https://www.edinburgh.ac.uk/study/student-life/"
  },
  {
    title: "How to Apply",
    img: k2, // Replace k2 with the actual image variable
    url: "https://www.edinburgh.ac.uk/study/apply/"
  },
  {
    title: "Alumni",
    img: k3, // Replace k3 with the actual image variable
    url: "https://www.edinburgh.ac.uk/alumni/"
  },

  {
    title: "Placements Statistics",
    img: k5, // Replace k5 with the actual image variable
    url: "https://www.edinburgh.ac.uk/careers/jobs-and-experience/work-experience/industrial-placements-/"
  },
  {
    title: "Brochure",
    img: k6, // Replace k6 with the actual image variable
    url: "https://www.edinburgh.ac.uk/business-school/programmes/brochures/"
  }
];

const SouthamptonKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace k1 with the actual image variable
    url: "https://www.southampton.ac.uk/study/student-life/"
  },
  {
    title: "How to Apply",
    img: k2, // Replace k2 with the actual image variable
    url: "https://www.southampton.ac.uk/study/apply/"
  },
  {
    title: "Alumni",
    img: k3, // Replace k3 with the actual image variable
    url: "https://www.southampton.ac.uk/alumni/"
  },

  {
    title: "Placements Statistics",
    img: k5, // Replace k5 with the actual image variable
    url: "https://www.southampton.ac.uk/careers/jobs-and-experience/work-experience/industrial-placements-/"
  },
  {
    title: "Brochure",
    img: k6, // Replace k6 with the actual image variable
    url: "https://www.southampton.ac.uk/business-school/programmes/brochures/"
  }
];

const DurhamKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace k1 with the actual image variable
    url: "https://www.durham.ac.uk/study/student-life/"
  },
  {
    title: "How to Apply",
    img: k2, // Replace k2 with the actual image variable
    url: "https://www.durham.ac.uk/study/apply/"
  },
  {
    title: "Alumni",
    img: k3, // Replace k3 with the actual image variable
    url: "https://www.durham.ac.uk/alumni/"
  },

  {
    title: "Placements Statistics",
    img: k5, // Replace k5 with the actual image variable
    url: "https://www.durham.ac.uk/careers/jobs-and-experience/work-experience/industrial-placements-/"
  },
  {
    title: "Brochure",
    img: k6, // Replace k6 with the actual image variable
    url: "https://www.durham.ac.uk/business-school/programmes/brochures/"
  }
];

const UNSWKnowMore = [
  {
    title: "Campus life",
    img: k1,
    url: "https://www.unsw.edu.au/study/discover/campus"
  },
  {
    title: "How to Apply",
    img: k2,
    url: "https://www.unsw.edu.au/research/hdr/application"
  },
  {
    title: "Alumni",
    img: k3,
    url: "https://www.unsw.edu.au/study/discover/our-alumni"
  },

  {
    title: "Placements Statistics",
    img: k5,
    url: "https://www.unsw.edu.au/research/hdr/industry-engagement"
  },
  {
    title: "Brochure",
    img: k6,
    url: "https://www.unsw.edu.au/research/hdr/industry-engagement"
  }
];
const MonashKnowMore = [
  {
    title: "Campus life",
    img: k1,
    url: "https://www.monash.edu/study/why-choose-monash/student-life"
  },
  {
    title: "How to Apply",
    img: k2,
    url: "https://www.monash.edu/study/how-to-apply"
  },
  {
    title: "Alumni",
    img: k3,
    url: "https://www.monash.edu/alumni"
  },

  {
    title: "Placements Statistics",
    img: k5,
    url: "https://www.monash.edu/business/current-students/ie/monash-sourced-placements"
  },
  {
    title: "Brochure",
    img: k6,
    url: "https://www.monash.edu.my/study/why/brochures"
  }
];
const QueenslandKnowMore = [
  {
    title: "Campus life",
    img: k1,
    url: "https://study.uq.edu.au/university-life"
  },
  {
    title: "How to Apply",
    img: k2,
    url: "https://apply.uq.edu.au/"
  },
  {
    title: "Alumni",
    img: k3,
    url: "https://alumni.uq.edu.au/notable-alumni"
  },

  {
    title: "Placements Statistics",
    img: k5,
    url: "https://my.uq.edu.au/starting-at-uq/prepare-for-semester/placements-employability"
  },
  {
    title: "Brochure",
    img: k6,
    url: "https://study.uq.edu.au/sites/default/files/2023-05/international-guide-undergraduate-postgraduate.pdf"
  }
];
const WesternAustraliaKnowMore = [
  {
    title: "Campus life",
    img: k1,
    url: "https://www.uwa.edu.au/students/campus-life"
  },
  {
    title: "How to Apply",
    img: k2,
    url: "https://www.uwa.edu.au/study/how-to-apply"
  },
  {
    title: "Alumni",
    img: k3,
    url: "https://alumni.uwa.edu.au/"
  },

  {
    title: "Placements Statistics",
    img: k5,
    url: "https://www.uwa.edu.au/students/my-career/work-placements-and-internships"
  },
  {
    title: "Brochure",
    img: k6,
    url: "https://www.uwa.edu.au/study/-/media/uwafs/docs/course-guides/2025/uwa-2025-international-course-guide.pdf"
  }
];
const AdelaideKnowMore = [
  {
    title: "Campus life",
    img: k1,
    url: "https://www.adelaide.edu.au/study/why-study-at-adelaide/campus-life"
  },
  {
    title: "How to Apply",
    img: k2,
    url: "https://www.adelaide.edu.au/study/undergraduate/how-to-apply-or-change-your-preferences"
  },
  {
    title: "Alumni",
    img: k3,
    url: "https://www.adelaide.edu.au/alumni/"
  },

  {
    title: "Placements Statistics",
    img: k5,
    url: "https://www.adelaide.edu.au/study/why-study-at-adelaide/careers-and-employability"
  },
  {
    title: "Brochure",
    img: k6,
    url: "https://international.adelaide.edu.au/agency-partners/information-for-students/brochure-downloads"
  }
];
const MelbourneKnowMore = [
  {
    title: "Campus life",
    img: k1,
    url: "https://about.unimelb.edu.au/priorities-and-partnerships/campus-development/campus-locations"
  },
  {
    title: "How to Apply",
    img: k2,
    url: "https://study.unimelb.edu.au/how-to-apply?in_c=hcta"
  },
  {
    title: "Alumni",
    img: k3,
    url: "https://www.unimelb.edu.au/alumni?in_c=mega"
  },

  {
    title: "Placements Statistics",
    img: k5,
    url: "https://mdhs.unimelb.edu.au/study/current-students/placements"
  },
  {
    title: "Brochure",
    img: k6,
    url: "https://study.unimelb.edu.au/connect-with-us/view-our-brochures"
  }
];
const MacquarieKnowMore = [
  {
    title: "Campus life",
    img: k1,
    url: "https://students.mq.edu.au/uni-life"
  },
  {
    title: "How to Apply",
    img: k2,
    url: "https://www.mq.edu.au/study/admissions-and-entry/apply"
  },
  {
    title: "Alumni",
    img: k3,
    url: "https://www.mq.edu.au/alumni"
  },

  {
    title: "Placements Statistics",
    img: k5,
    url: "https://students.mq.edu.au/careers/get-ready/pace"
  },
  {
    title: "Brochure",
    img: k6,
    url: "https://www.mq.edu.au/__data/assets/pdf_file/0019/1290052/Macquarie_University_Study_Guide_2025.pdf"
  }
];
const UTSKnowMore = [
  {
    title: "Campus life",
    img: k1,
    url: "https://www.uts.edu.au/current-students/campus-life"
  },
  {
    title: "How to Apply",
    img: k2,
    url: "https://www.uts.edu.au/study/information-technology/essential-information/how-apply"
  },
  {
    title: "Alumni",
    img: k3,
    url: "https://www.uts.edu.au/partners-and-community/alumni-and-supporters"
  },

  {
    title: "Placements Statistics",
    img: k5,
    url: "https://www.uts.edu.au/about/university/facts-figures-and-rankings/uts-graduate-employment"
  },
  {
    title: "Brochure",
    img: k6,
    url: "https://www.uts.edu.au/study/international/essential-information/download-brochure"
  }
];
const ANUKnowMore = [
  {
    title: "Campus life",
    img: k1,
    url: "https://www.anu.edu.au/students/student-life"
  },
  {
    title: "How to Apply",
    img: k2,
    url: "https://study.anu.edu.au/apply"
  },
  {
    title: "Alumni",
    img: k3,
    url: "https://www.anu.edu.au/alumni"
  },

  {
    title: "Placements Statistics",
    img: k5,
    url: "https://www.anu.edu.au/jobs"
  },
  {
    title: "Brochure",
    img: k6,
    url: "https://programsandcourses.anu.edu.au/"
  }
];
const SydneyKnowMore = [
  {
    title: "Campus life",
    img: k1,
    url: "https://www.sydney.edu.au/about-us/campuses.html"
  },
  {
    title: "How to Apply",
    img: k2,
    url: "https://www.sydney.edu.au/about-us/campuses.html"
  },
  {
    title: "Alumni",
    img: k3,
    url: "https://www.sydney.edu.au/engage/alumni.html"
  },

  {
    title: "Placements Statistics",
    img: k5,
    url: "https://www.sydney.edu.au/students/placement-internship-opportunities.html"
  },
  {
    title: "Brochure",
    img: k6,
    url: "https://www.sydney.edu.au/business/our-research/research-partnerships/download-our-brochure.html"
  }
];
const TrentKnowMore = [
  {
    title: "Campus Life",
    img: k1,
    url: "https://www.trentu.ca/currentstudents/campus-life"
  },
  {
    title: "How to Apply",
    img: k2,
    url: "https://www.trentu.ca/online/applyregister/how-to-apply"
  },
  {
    title: "Alumni",
    img: k3,
    url: "https://mycommunity.trentu.ca/"
  },

  {
    title: "Placement Statistics",
    img: k5,
    url: "https://www.trentu.ca/business/experience/undergraduate-experience/placements-internships-and-co-ops-school-business"
  },
  {
    title: "Brochure",
    img: k6,
    url: "https://www.trentu.ca/futurestudents/viewbook"
  }
];
const TrinityKnowMore = [
  {
    title: "Campus Life",
    img: k1,
    url: "https://www.twu.ca/academics/laurentian-leadership-centre/community-life"
  },
  {
    title: "How to Apply",
    img: k2,
    url: "https://www.twu.ca/apply-twu"
  },
  {
    title: "Alumni",
    img: k3,
    url: "https://www.twu.ca/alumni"
  },

  {
    title: "Placements Statistics",
    img: k5,
    url: "https://www.twu.ca/about-us/facts-figures"
  },
  {
    title: "Brochure",
    img: k6,
    url: "https://www.twu.ca/sites/default/files/2024-05/EM%20UG%20TSC%202023_Viewbook%20INTL_Spreads.pdf"
  }
];
const RoyalRoadsKnowMore = [
  {
    title: "Campus Life",
    img: k1,
    url: "https://www.royalroads.ca/current-students/student-life"
  },
  {
    title: "How to Apply",
    img: k2,
    url: "https://www.royalroads.ca/admission/applications/how-apply"
  },
  {
    title: "Alumni",
    img: k3,
    url: "https://www.royalroads.ca/alumni"
  },

  {
    title: "Placements and Career Support",
    img: k5,
    url: "https://www.royalroads.ca/current-students/career-learning-development"
  },
  {
    title: "Brochure",
    img: k6,
    url: "https://www.royalroads.ca/sites/default/files/2024-07/24-6197_Recruitment_2024-25-Viewbook_WEB.pdf"
  }
];
const WaterlooKnowMore = [
  {
    title: "Campus Life",
    img: k1,
    url: "https://uwaterloo.ca/students/campus-life"
  },
  {
    title: "How to Apply",
    img: k2,
    url: "https://uwaterloo.ca/future-students/admissions"
  },
  {
    title: "Alumni",
    img: k3,
    url: "https://uwaterloo.ca/alumni/"
  },

  {
    title: "Placements Statistics",
    img: k5,
    url: "https://uwaterloo.ca/co-operative-education/about-co-op/employment-statistics"
  },
  {
    title: "Brochure",
    img: k6,
    url: "https://uwaterloo.ca/future-students/request-viewbook"
  }
];
const CalgaryKnowMore = [
  {
    title: "Campus Life",
    img: k1,
    url: "https://conted.ucalgary.ca/international/programs.jsp"
  },
  {
    title: "How to Apply",
    img: k2,
    url: "https://conted.ucalgary.ca/"
  },
  {
    title: "Alumni",
    img: k3,
    url: "https://alumni.ucalgary.ca/programs/alumni"
  },

  {
    title: "Placements Statistics",
    img: k5,
    url: "https://conted.ucalgary.ca/international/programs.jsp"
  },
  {
    title: "Brochure",
    img: k6,
    url: "https://www.ucalgary.ca/pubs/calendar/archives/2003pdf/ContEd.pdf"
  }
];
const DalhousieKnowMore = [
  {
    title: "Campus Life",
    img: k1,
    url: "https://www.dal.ca/campus_life.html"
  },
  {
    title: "How to Apply",
    img: k2,
    url: "https://www.dal.ca/admissions/how-to-apply.html"
  },
  {
    title: "Alumni",
    img: k3,
    url: "https://www.dal.ca/alumni.html"
  },

  {
    title: "Placements Statistics",
    img: k5,
    url: "https://www.dal.ca/hire-dal.html"
  },
  {
    title: "Brochure",
    img: k6,
    url: "https://www.dal.ca/content/dam/www/study/viewbooks/domestic-viewbook.pdf"
  }
];
const BrockKnowMore = [
  {
    title: "Campus Life",
    img: k1,
    url: "https://discover.brocku.ca/international/student-life/"
  },
  {
    title: "How to Apply",
    img: k2,
    url: "https://brocku.ca/graduate-studies/future-students/apply/"
  },
  {
    title: "Alumni",
    img: k3,
    url: "https://brocku.ca/alumni/"
  },

  {
    title: "Placements Statistics",
    img: k5,
    url: "https://brocku.ca/brock-news/wp-content/uploads/2013/11/graduate-employment-survey.pdf"
  },
  {
    title: "Brochure",
    img: k6,
    url: "https://brocku.ca/continuing-education/esl/brochures-resources/"
  }
];
const WindsorKnowMore = [
  {
    title: "Campus Life",
    img: k1,
    url: "https://www.uwindsor.ca/639668/around-university"
  },
  {
    title: "How to Apply",
    img: k2,
    url: "https://www.future.uwindsor.ca/?utm_source=Google&utm_medium=cpc&utm_campaign=HSM-UWindsor-24-25&gad_source=1&gclid=Cj0KCQjwjY64BhCaARIsAIfc7YZB_NtsamcIlgmt8vuKSpscr5l5iDzJ3J2ehjoBOH7D5pS18Vht9CkaAkDgEALw_wcB"
  },
  {
    title: "Alumni",
    img: k3,
    url: "https://www.uwindsor.ca/alumni/"
  },

  {
    title: "Placements Statistics",
    img: k5,
    url: "https://www.uwindsor.ca/dailynews/2023-10-02/windsor-jumps-best-ever-placement-global-university-ranking"
  },
  {
    title: "Brochure",
    img: k6,
    url: "https://www.uwindsor.ca/studentrecruitment/sites/uwindsor.ca.studentrecruitment/files/2017_2018_transfer_information_guide.pdf"
  }
];
const VCCKnowMore = [
  {
    title: "Campus Life",
    img: k1,
    url: "https://www.vcc.ca/about/college-information/about-vcc/"
  },
  {
    title: "How to Apply",
    img: k2,
    url: "https://www.vcc.ca/international/future-students/apply/how-to-apply/"
  },
  {
    title: "Alumni",
    img: k3,
    url: "https://www.vcc.ca/about/foundation-and-alumni/alumni/"
  },

  {
    title: "Placements Statistics",
    img: k5,
    url: "https://www.vcc.ca/programscourses/graduate-outcomes/"
  },
  {
    title: "Brochure",
    img: k6,
    url: "https://www.vcc.ca/international/about/overview/brochure/"
  }
];
const TorontoKnowMore = [
  {
    title: "Campus Life",
    img: k1,
    url: "https://future.utoronto.ca/university-life/campus-life/"
  },
  {
    title: "How to Apply",
    img: k2,
    url: "https://future.utoronto.ca/apply/"
  },
  {
    title: "Alumni",
    img: k3,
    url: "https://alumni.utoronto.ca/"
  },

  {
    title: "Placements Statistics",
    img: k5,
    url: "https://www.sgs.utoronto.ca/about/explore-our-data/career-outcomes/"
  },
  {
    title: "Brochure",
    img: k6,
    url: "https://internationalprograms.utoronto.ca/brochures/"
  }
];

//Germany

const LMUKnowMore = [
  {
    title: "Campus Life",
    img: k1,
    url: "https://www.lmu.de/en/workspace-for-students/student-life/"
  },
  {
    title: "How to Apply",
    img: k2,
    url: "https://www.lmu.de/en/study/degree-students/applications-for-admission/"
  },
  {
    title: "Alumni",
    img: k3,
    url: "https://www.lmu.de/en/about-lmu/international-network/"
  },

  {
    title: "Placements Statistics",
    img: k5,
    url: "https://www.econ.lmu.de/en/faculty/mgse/job-market-candidates-and-placement"
  },
  {
    title: "Brochure",
    img: k6,
    url: "https://www.m-datascience.mathematik-informatik-statistik.uni-muenchen.de/program/curriculum/overview_modules2.pdf"
  }
];

const TUMKnowMore = [
  {
    title: "Campus Life",
    img: k1,
    url: "https://www.tum.de/en/community/campus-life"
  },
  {
    title: "How to Apply",
    img: k2,
    url: "https://www.tum.de/en/studies/application"
  },
  {
    title: "Alumni",
    img: k3,
    url: "https://www.community.tum.de/en/alumni/"
  },

  {
    title: "Placements Statistics",
    img: k5,
    url: "https://www.tum.de/en/studies/during-your-studies/living-and-working/jobs-and-internships"
  },
  {
    title: "Brochure",
    img: k6,
    url: "https://www.ub.tum.de/en/brochure-tum-students"
  }
];

const HeidelbergUniversityKnowMore = [
  {
    title: "Campus Life",
    img: k1,
    url: "https://www.heidelberg.edu/student-life"
  },
  {
    title: "How to Apply",
    img: k2,
    url: "https://www.uni-heidelberg.de/en/study/application-enrolment"
  },
  {
    title: "Alumni",
    img: k3,
    url: "https://www.heidelberg.edu/alumni"
  },

  {
    title: "Placements Statistics",
    img: k5,
    url: "https://www.uni-heidelberg.de/en/university/facts-figures"
  },
  {
    title: "Brochure",
    img: k6,
    url: "https://www.uni-heidelberg.de/md/zentral/international/broschuere_kurzinfo_en.pdf"
  }
];

const HumboldtUniversityKnowMore = [
  {
    title: "Campus Life",
    img: k1,
    url: "https://www.hu-berlin.de/en"
  },
  {
    title: "How to Apply",
    img: k2,
    url: "https://www.hu-berlin.de/en/studies/compass.-the-central-information-service-en/wie-en"
  },
  {
    title: "Alumni",
    img: k3,
    url: "https://www.hu-berlin.de/en/alumni/alumni-portal/standardseite"
  },

  {
    title: "Placements Statistics",
    img: k5,
    url: "https://www.hu-berlin.de/en/about/humboldt-universitaet-zu-berlin/facts/standardseite"
  },
  {
    title: "Brochure",
    img: k6,
    url: "https://www.hu-berlin.de/en/studies/counselling/course-catalogue/Standardseite"
  }
];

const RWTHAachenKnowMore = [
  {
    title: "Campus Life",
    img: k1,
    url: "https://www.rwth-aachen.de/cms/root/wirtschaft/~ekt/Campusprojekt/lidx/1/"
  },
  {
    title: "How to Apply",
    img: k2,
    url: "https://www.rwth-aachen.de/cms/root/studium/vor-dem-studium/~egg/bewerbung-um-einen-studienplatz/?lidx=1"
  },
  {
    title: "Alumni",
    img: k3,
    url: "https://www.rwth-aachen.de/cms/root/zielgruppenportale/~uzl/alumni/?lidx=1"
  },
  {
    title: "Placements Statistics",
    img: k5,
    url: "https://www.rwth-aachen.de/cms/root/die-rwth/profil/~enw/daten-fakten/?lidx=1"
  },
  {
    title: "Brochure",
    img: k6,
    url: "https://www.imse.rwth-aachen.de/cms/imse/forschung/ausgewaehlte-veroeffentlichungen/~pafk/institutsbroschuere/?lidx=1"
  }
];

const FUBerlinKnowMore = [
  {
    title: "Campus Life",
    img: k1,
    url: "https://www.fubis.org/4_life/index.html"
  },
  {
    title: "How to Apply",
    img: k2,
    url: "https://www.fu-berlin.de/en/studium/bewerbung/index.html"
  },
  {
    title: "Alumni",
    img: k3,
    url: "https://www.fu-berlin.de/en/sites/alumni/index.html"
  },
  // {
  //   title: "Accommodation",
  //   img: k4,
  //   url: "https://www.fu-berlin.de/en/sites/unterbringung/index.html"
  // },
  {
    title: "Placements Statistics",
    img: k5,
    url: "https://www.fu-berlin.de/en/studium/studieren/studienorganisation/statistik/index.html"
  },
  {
    title: "Brochure",
    img: k6,
    url: "https://www.fu-berlin.de/en/sites/fubest/info/download/index.html"
  }
];

const UOFKnowMore = [
  {
    title: "Campus Life",
    img: k1,
    url: "https://www.ucf.uni-freiburg.de/"
  },
  {
    title: "How to Apply",
    img: k2,
    url: "https://www.studium.uni-freiburg.de/en/application/application"
  },
  {
    title: "Alumni",
    img: k3,
    url: "https://www.ucf.uni-freiburg.de/liberal-arts-and-sciences/after-las/ucf-alumni"
  },
  // {
  //   title: "Accommodation",
  //   img: k4,
  //   url: "https://www.studium.uni-freiburg.de/en/counseling/welcome-guide-for-international-students/housing-where-can-i-live"
  // },
  {
    title: "Placements Statistics",
    img: k5,
    url: "https://www.studium.uni-freiburg.de/en/student-services/career/german-federal-employment-agency-folder/job-placement"
  },
  {
    title: "Brochure",
    img: k6,
    url: "https://kommunikation.uni-freiburg.de/the_media/flyers-brochures/flyers-brochures"
  }
];


const UOTKnowMore = [
  {
    title: "Campus Life",
    img: k1,
    url: "https://uni-tuebingen.de/en/university/life-on-campus/"
  },
  {
    title: "How to Apply",
    img: k2,
    url: "https://uni-tuebingen.de/en/study/application-and-enrollment/"
  },
  {
    title: "Alumni",
    img: k3,
    url: "https://uni-tuebingen.de/en/alumni/"
  },
  // {
  //   title: "Accommodation",
  //   img: k4,
  //   url: "https://uni-tuebingen.de/en/international/welcome-center/accommodation-service/"
  // },
  {
    title: "Placements Statistics",
    img: k5,
    url: "https://uni-tuebingen.de/en/university/profile/facts-and-figures/"
  },
  {
    title: "Brochure",
    img: k6,
    url: "https://uni-tuebingen.de/en/university/news-and-publications/publications/university-of-tuebingen-brochure/"
  }
];


const UOMKnowMore = [
  {
    title: "Campus Life",
    img: k1,
    url: "https://www.uni-mannheim.de/en/campus/"
  },
  {
    title: "How to Apply",
    img: k2,
    url: "https://www.uni-mannheim.de/en/academics/before-your-studies/applying/"
  },
  {
    title: "Alumni",
    img: k3,
    url: "https://www.uni-mannheim.de/en/academics/coming-to-mannheim/international-students/starting-your-career/international-alumni/"
  },
  // {
  //   title: "Accommodation",
  //   img: k4,
  //   url: "https://www.uni-mannheim.de/en/academics/coming-to-mannheim/exchange-students/housing/"
  // },
  {
    title: "Placements Statistics",
    img: k5,
    url: "https://www.uni-mannheim.de/gess/programs/cdse/student-information/job-placement/"
  },
  {
    title: "Brochure",
    img: k6,
    url: "https://www.uni-mannheim.de/en/academics/coming-to-mannheim/exchange-students/courses/course-catalog/"
  }
];

const UOGKnowMore = [
  {
    title: "Campus Life",
    img: k1,
    url: "https://www.uni-goettingen.de/en/student+life+in+g%C3%B6ttingen/50486.html"
  },
  {
    title: "How to Apply",
    img: k2,
    url: "https://www.uni-goettingen.de/en/application+and+enrolment+(for+eu+and+non-eu+applicants)/46792.html"
  },
  {
    title: "Alumni",
    img: k3,
    url: "https://www.uni-goettingen.de/en/590707.html"
  },
  // {
  //   title: "Accommodation",
  //   img: k4,
  //   url: "https://www.uni-goettingen.de/en/accommodation+for+students/50488.html"
  // },
  {
    title: "Placements Statistics",
    img: k5,
    url: "https://www.uni-goettingen.de/en/31587.html"
  },
  {
    title: "Brochure",
    img: k6,
    url: "https://www.uni-goettingen.de/en/new-brochure--a-great-hall-for-the-university-of-goettingen/314257.html"
  }
];


//France

const UPSLKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://psl.eu/en/campus-lifepsl",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://psl.eu/en/international-admissions-procedures-psl",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://psl.eu/vie-de-campus/vie-associative/psl-alumni",
  },
  // {
  //   title: "Accommodation",
  //   img: "accommodationImg", // Replace with actual image variable if needed
  //   url: "https://psl.eu/en/lifepsl/psl-housing-service/accomodation-in-public-student-residencies",
  // },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.psl.eu/en/",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://www.psl.eu/sites/default/files/Admissions/PSL%20Degrees%20Prospectus%202020-21.pdf",
  },
];

const UPSKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://ens-paris-saclay.fr/en/campus",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://ens-paris-saclay.fr/en/application",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://ens-paris-saclay.fr/en/profil/alumni",
  },
  // {
  //   title: "Accommodation",
  //   img: "accommodationImg", // Replace with actual image variable if needed
  //   url: "https://ens-paris-saclay.fr/en/campus/housing",
  // },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.universite-paris-saclay.fr/en/",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://ressources.campusfrance.org/pratique/etablissements/en/pole_saclay_en.pdf",
  },
];
const SUKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://www.sorbonne-universite.fr/en/campus-life-1/student-life",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://www.sorbonne-universite.fr/en/education/study-sorbonne-university/degree-seeking-students/international-application-and/applying",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://www.sorbonne-universite.fr/en/university/alumni",
  },
  // {
  //   title: "Accommodation",
  //   img: "accommodationImg", // Replace with actual image variable if needed
  //   url: "https://www.sorbonne-universite.fr/en/campus-life-1/student-life/housing",
  // },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.sorbonne-universite.fr/sites/default/files/media/2023-07/HRS4R-Survey-report-Postdocs2022-EN.pdf",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://www.sorbonne-universite.fr/en/education/study-sorbonne-university/our-academic-programs",
  },
];
const UGAKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://www.univ-grenoble-alpes.fr/campus-/campus-844372.kjsp",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://www.univ-grenoble-alpes.fr/education/how-to-apply/applying-and-registering/apply/apply-625777.kjsp",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://www.univ-grenoble-alpes.fr/alumni/alumni-1106202.kjsp",
  },
  // {
  //   title: "Accommodation",
  //   img: "accommodationImg", // Replace with actual image variable if needed
  //   url: "https://international.univ-grenoble-alpes.fr/getting-organized/accommodation/looking-for-accommodation/accommodation-options/accommodation-options-783857.kjsp",
  // },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.univ-grenoble-alpes.fr/education/programs/uga-internships/uga-internships-786051.kjsp",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://formations.univ-grenoble-alpes.fr/en/index.html",
  },
];
const UDSKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://en.unistra.fr/campus-life",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://en.unistra.fr/study/apply",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://en.unistra.fr/alumni",
  },
  // {
  //   title: "Accommodation",
  //   img: "accommodationImg", // Replace with actual image variable if needed
  //   url: "https://en.unistra.fr/campus-life/accommodation",
  // },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://welcome.unistra.fr/campus-life/welcome-to-the-university-of-strasbourg/career-counselling-internships-jobs",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://en.unistra.fr/fileadmin/upload/unistra/international/Guide_welcome/Guide_Welcome_Unistra_En_WEB.pdf",
  },
];
const UDMKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://www.umontpellier.fr/en/universite/presidence/directions-et-services-communs/direction-de-la-vie-des-campus",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://www.umontpellier.fr/en/formation/admission-et-inscription",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://www.umontpellier.fr/en/campus/vie-associative/alumniae",
  },
  // {
  //   title: "Accommodation",
  //   img: "accommodationImg", // Replace with actual image variable if needed
  //   url: "https://www.montpellier-management.fr/en/student-life/housing/",
  // },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.umontpellier.fr/en/formation/information-orientation-insertion",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://formations-en.umontpellier.fr/fr/index.html",
  },
];
const UDLKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://www.univ-lille.fr/en/campus-life",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://www.univ-lille.fr/en/studies/apply-enrol/applying",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://international.univ-lille.fr/en/come-to-the-university-of-lille/",
  },
  // {
  //   title: "Accommodation",
  //   img: "accommodationImg", // Replace with actual image variable if needed
  //   url: "https://international.univ-lille.fr/en/maison-internationale/procedures-and-practical-information/accommodation-in-lille/",
  // },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://international.univ-lille.fr/en/come-to-the-university-of-lille/students/traineeship/",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://international.univ-lille.fr/fileadmin/user_upload/docs__pdf__autre_/international/Degree-programmes-Universite-Lille.pdf",
  },
];
const UDNKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://english.univ-nantes.fr/student-life/life-on-campus",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://english.univ-nantes.fr/education/application-procedures",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://www-univ--nantes-fr.translate.goog/ancien-etudiant/rejoignez-les-alumni-de-luniversite-de-nantes-pour-beneficier-dun-acces-au-career-center?_x_tr_sl=fr&_x_tr_tl=en&_x_tr_hl=en&_x_tr_pto=sc",
  },
  // {
  //   title: "Accommodation",
  //   img: "accommodationImg", // Replace with actual image variable if needed
  //   url: "https://www.univ-nantes.fr/international/venir-a-nantes/international-students-accommmodation-and-housing-aid",
  // },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://www-univ--nantes-fr.translate.goog/formation/orientation-parcours-metiers/le-devenir-de-nos-diplomes?_x_tr_sl=fr&_x_tr_tl=en&_x_tr_hl=en&_x_tr_pto=sc",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://www.univ-nantes.fr/international/venir-a-nantes/incoming-exchange-students-class-catalog",
  },
];
const UDBKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://www.u-bordeaux.fr/en/campus/campus-life-and-activities#:~:text=Campus%20Life%20Councils&text=The%20councils%20are%20made%20up,life%2C%20food%20services%20and%20transport.",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://www.u-bordeaux.fr/en/international/come-to-bordeaux/international-students/applying-for-admission",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://alumni.u-bordeaux.fr/",
  },
  // {
  //   title: "Accommodation",
  //   img: "accommodationImg", // Replace with actual image variable if needed
  //   url: "https://www.u-bordeaux.fr/en/campus/daily-life/finding-accommodation",
  // },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.u-bordeaux.fr/en/about-us/university-employment",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://ressources.campusfrance.org/pratique/etablissements/en/univ_bordeaux_en.pdf",
  },
];
const UPPSKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://welcome.pantheonsorbonne.fr/en/student-life",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://international.pantheonsorbonne.fr/en/join-paris-1-pantheon-sorbonne/full-degree-student",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://alumni.pantheonsorbonne.fr/",
  },
  // {
  //   title: "Accommodation",
  //   img: k5, // Replace with actual image variable if needed
  //   url: "https://international.pantheonsorbonne.fr/en/join-paris-1-pantheon-sorbonne/accommodation-paris",
  // },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://international.pantheonsorbonne.fr/en",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://psme.pantheonsorbonne.fr/sites/default/files/2021-03/BROCHURE%20PSME_v4.pdf",
  },
];

//Italy

const PDMilanoKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://www.polimi.it/en/campus-and-services",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://www.polimi.it/en/prospective-students/how-to-apply/admission-to-laurea-magistrale/foreign-qualification/application",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://alumni.polimi.it/en/",
  },
  // {
  //   title: "Accommodation",
  //   img: k5, // Replace with actual image variable if needed
  //   url: "https://www.polimi.it/en/campus-e-servizi/student-accommodation",
  // },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.polimi.it/en/prospective-students/toward-the-world-of-careers/career-data#:~",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://www.polimi.it/en/the-politecnico/university-projects",
  },
];

const UOMilanKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://www.unimi.it/en/study/student-life",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://www.unimi.it/en/international/coming-abroad/enrol-programme",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://www.unimi.it/en/university/la-statale/our-heritage-our-future/hundred-years-life-university-milan",
  },
  // {
  //   title: "Accommodation",
  //   img: k5, // Replace with actual image variable if needed
  //   url: "https://www.unimi.it/en/study/financial-support/accommodation",
  // },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.unimi.it/en/third-mission/social-responsibility/university-and-enterprises/placement-and-job-orientation-events",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://www.unimi.it/sites/default/files/2020-08/Welcome_ENG_Web_2020_2021_0.pdf",
  },
];

const UOBolognaKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://www.unibo.it/en/study/life-at-university-and-in-the-city",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://www.unibo.it/en/study/international-experiences/Studying-abroad/General-information-on-Erasmus/Participation-methods",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://www.unibo.it/en/university/who-we-are/alumni",
  },
  // {
  //   title: "Accommodation",
  //   img: k5, // Replace with actual image variable if needed
  //   url: "https://www.unibo.it/en/university/international-outreach/international-staff-professors-and-researchers/information-for-international-teaching-and-research-staff-hosted-at-the-university-of-bologna/Accommodation-for-international-guests",
  // },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://jobplacement.unibo.it/en/companies/meet-our-graduates#:~",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://www.unibo.it/en/attachments/university-of-bologna-brochure-2024/@@download/file/Brochure-Unibo-2024-ENG.pdf",
  },
];

const UOFlorenceKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://www.studentlifeflorence.it/#:~",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://apply.unifi.it/",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://www.unifi.it/en/alumni",
  },
  // {
  //   title: "Accommodation",
  //   img: k5, // Replace with actual image variable if needed
  //   url: "https://www.unifi.it/en/university/around-world/welcome-unifi/accommodation",
  // },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.unifi.it/en/study-us/after-graduation/job-placement-and-careers",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://www.unifi.it/en/study-us/degree-programmes/international-courses",
  },
];

const UOPaduaKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://www.unipd.it/en/course/campus-life#:~",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://apply.unipd.it/",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://www.unipd.it/en/eminent-alumni",
  },
  // {
  //   title: "Accommodation",
  //   img: k5, // Replace with actual image variable if needed
  //   url: "https://www.unipd.it/en/accommodation-erasmus-semp",
  // },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.unipd.it/en/job-placement",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://www.unipd.it/en/sites/en.unipd.it/files/StudyWithUs_2023_2024.pdf",
  },
];

const UOTurinKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://en.unito.it/living-turin",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://apply.unito.it/",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://www.turin-ip.com/whcpd-experience/alumni.html",
  },
  // {
  //   title: "Accommodation",
  //   img: k5, // Replace with actual image variable if needed
  //   url: "https://en.unito.it/news/are-you-looking-accommodation-find-out-opportunities-provided-university-turin",
  // },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://en.unito.it/services/job-placement",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://www.turin-ip.com/images/brochure_WIPO_24-25_DEF.pdf",
  },
];

const RLSKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://www.uniroma1.it/en/pagina-strutturale/sapienza",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://web.uniroma1.it/cdaingchim/admission-process/admission-process#:~",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://www.uniroma1.it/en/pagina/illustrious-alumni-and-professors",
  },
  // {
  //   title: "Accommodation",
  //   img: k5, // Replace with actual image variable if needed
  //   url: "https://www.uniroma1.it/en/pagina/student-housing",
  // },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.uniroma1.it/en/notizia/sapienza-amongst-top-100-universities-worldwide-graduate-employment",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://www.uniroma1.it/sites/default/files/field_file_allegati/programmesinenglish_web.pdf",
  },
];

const UONaplesKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://www.international.unina.it/academic-and-student-life/",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://www.international.unina.it/opportunities/call-for-applicants/",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://www.international.unina.it/",
  },
  // {
  //   title: "Accommodation",
  //   img: k5, // Replace with actual image variable if needed
  //   url: "https://www.international.unina.it/welcome-desk/",
  // },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.international.unina.it/education/international-students-opportunities/",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://www.international.unina.it/mediafile/2023/05/BANDO-ENG-Study-in-Naples-2023-2024.pdf",
  },
];

const UOPisaKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://www.unipi.it/index.php/student-services",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://www.unipi.it/index.php/how-to-apply",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://alumni.unipi.it/",
  },
  // {
  //   title: "Accommodation",
  //   img: k5, // Replace with actual image variable if needed
  //   url: "https://www.unipi.it/index.php/student-services/item/2322",
  // },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.unipi.it/index.php/job-offers",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://www.unipi.it/index.php/programmes-held-in-english",
  },
];

const IEDKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://www.ied.edu/students?_",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://www.ied.edu/study-in-italy/undergraduate",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://www.ied.edu/alumni?_",
  },
  // {
  //   title: "Accommodation",
  //   img: k5, // Replace with actual image variable if needed
  //   url: "https://www.ied.edu/milan/accommodation-milan",
  // },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.ied.edu/success-stories?_",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://www.ied.it/other-pdf/Resources/Brochure-internazionale_web.pdf",
  },
];

//Ireland

const TCDublinKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://www.tcd.ie/medicine/undergraduate-medicine/student-life/",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://www.tcd.ie/study/international/how-to-apply/",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://www.tcd.ie/alumni/",
  },
  // {
  //   title: "Accommodation",
  //   img: k5, // Replace with actual image variable if needed
  //   url: "https://www.tcd.ie/accommodation/",
  // },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.tcd.ie/tcpid/business-partners/graduate-internships.php",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://www.tcd.ie/study/prospectus/",
  },
];

const UCDublinKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://www.ucd.ie/global/studentexperience/campuslife/",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://www.ucd.ie/graduateadmissions/admissions/howtoapply/",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://www.ucd.ie/alumni/",
  },
  // {
  //   title: "Accommodation",
  //   img: k5, // Replace with actual image variable if needed
  //   url: "https://www.ucd.ie/global/study-at-ucd/accommodation/",
  // },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.ucd.ie/globalchange/study/workplacementandinternship/",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://www.ucd.ie/global/study-at-ucd/brochures/",
  },
];

const UOCorkKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://www.ucc.ie/en/discover/campus/",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://www.ucc.ie/en/apply/",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://alumni.ucc.ie/s/1901/bp19/home.aspx?gid=2&pgid=61",
  },
  {
    title: "Accommodation",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.ucc.ie/en/international/studentinfohub/beforeyouarrive/accommodation/",
  },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.ucc.ie/en/media/support/careers/UCCEmployabilityandEmploymentGuide.pdf",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://www.ucc.ie/en/matsci/study-maths/brochures/",
  },
];

const UOGalwayKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://www.universityofgalway.ie/university-life/",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://www.universityofgalway.ie/courses/how-to-apply/",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://www.universityofgalway.ie/alumni-friends/",
  },
  {
    title: "Accommodation",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.universityofgalway.ie/student-life/accommodation/",
  },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.universityofgalway.ie/career-development-centre/workbasedlearning/studentplacement/",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://www.universityofgalway.ie/colleges-and-schools/arts-social-sciences-and-celtic-studies/ba-degrees/brochures/",
  },
];

const DCUKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://www.dcu.ie/studentrecruitment/student-life",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://www.dcu.ie/registry/how-to-apply-dcu",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://www.dcu.ie/alumni",
  },
  {
    title: "Accommodation",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.dcuaccommodation.ie/",
  },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.dcu.ie/placement",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://www.dcu.ie/studentrecruitment/Prospectus-Request",
  },
];

const UOLimerickKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://campuslife.ul.ie/",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://www.ul.ie/gps/about/how-apply",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://www.ul.ie/ul-alumni",
  },
  {
    title: "Accommodation",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.ul.ie/global/incoming-students/before-you-arrive/accommodation",
  },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.ul.ie/enterprise-innovation/student-work-placement",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://www.ul.ie/courses/about-ul/prospectus",
  },
];

const MUKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://www.maynoothuniversity.ie/campus-life",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://www.maynoothuniversity.ie/study-maynooth/how-apply",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://www.maynoothuniversity.ie/daro/our-graduates",
  },
  {
    title: "Accommodation",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.maynoothuniversity.ie/accommodation",
  },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.maynoothuniversity.ie/careers/students/placement",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://www.maynoothuniversity.ie/study-maynooth/download-prospectus-booklets",
  },
];

const TUDublinKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://www.tudublin.ie/for-students/student-life/",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://www.tudublin.ie/study/international-students/how-to-apply/",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://www.tudublin.ie/connect/graduates/notable-alumni/",
  },
  // {
  //   title: "Accommodation",
  //   img: k5, // Replace with actual image variable if needed
  //   url: "https://www.tudublin.ie/for-students/student-services-and-support/accommodation--living-in-dublin/",
  // },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.tudublin.ie/for-students/career-development-centre/work-placements/",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://www.tudublin.ie/study/undergraduate/cao/undergraduate-prospectus/",
  },
];

const DBSKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://www.dbs.ie/student-experience/dbs-student-experience",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://www.dbs.ie/application-form",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://www.dbs.ie/student-experience/alumni",
  },
  {
    title: "Accommodation",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.dbs.ie/international-students/international-non-eu-students/accommodation",
  },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.dbs.ie/",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://www.dbs.ie/docs/default-source/international-brochure/ftug-brochure-2023.pdf?sfvrsn=e198b7ea_6",
  },
];

const RCSIKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://www.rcsi.com/dublin/student-life/life-on-campus",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://www.rcsi.com/dublin/undergraduate/application-information",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://www.rcsi.com/alumni",
  },
  {
    title: "Accommodation",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.rcsi.com/dublin/student-life/undergraduate-essentials/accommodation",
  },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.rcsi.com/about/publications/annual-report",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://www.rcsi.com/dublin/undergraduate/application-information/prospectus",
  },
];

// json data //  New Zealand 
// KnowMore


const AucklandKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://www.auckland.ac.nz/en/on-campus/life-on-campus/campus-life-directorate.html",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://www.auckland.ac.nz/en/study/applications-and-admissions/how-to-apply.html",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://www.auckland.ac.nz/en/alumni.html",
  },
  // {
  //   title: "Accommodation",
  //   img: k5, // Replace with actual image variable if needed
  //   url: "https://www.auckland.ac.nz/en/on-campus/accommodation/accommodation-options.html",
  // },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.auckland.ac.nz/assets/alumni/our-alumni/GDS/Graduate%20Destination%20Survey%202021%20updated%20on%20April%202023.pdf",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://www.auckland.ac.nz/en/study/study-options/undergraduate-study-options/undergraduate-prospectus.html",
  },
];

const MasseyKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://www.massey.ac.nz/student-life/",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://www.massey.ac.nz/study/apply-to-study/",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://www.massey.ac.nz/study/library/campus-libraries-and-off-campus-library-services/alumni-library-services/",
  },
  // {
  //   title: "Accommodation",
  //   img: k5, // Replace with actual image variable if needed
  //   url: "https://www.massey.ac.nz/student-life/accommodation/",
  // },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.massey.ac.nz/about/student-facts-and-figures/",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://www.massey.ac.nz/documents/797/International-Prospectus.pdf",
  },
];

const WaikatoKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://www.waikato.ac.nz/int/students/",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://www.waikato.ac.nz/int/study/apply/apply-now/",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://www.waikato.ac.nz/int/alumni/",
  },
  // {
  //   title: "Accommodation",
  //   img: k5, // Replace with actual image variable if needed
  //   url: "https://www.waikato.ac.nz/int/students/accommodation/",
  // },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.waikato.ac.nz/int/study/subject-regulations/work-placements/",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://www.waikato.ac.nz/int/study/prospectus/",
  },
];

const VictoriaKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://www.wgtn.ac.nz/students/student-life",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://www.wgtn.ac.nz/study/apply-enrol",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://www.wgtn.ac.nz/engage/alumni",
  },
  // {
  //   title: "Accommodation",
  //   img: k5, // Replace with actual image variable if needed
  //   url: "https://www.wgtn.ac.nz/accommodation",
  // },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.wgtn.ac.nz/careers/pdf-resources/2021-2022-graduate-destinations-report.pdf",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://www.wgtn.ac.nz/international/documents/international-prospectus.pdf",
  },
];

const CanterburyKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://www.canterbury.ac.nz/life/student-life",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://www.canterbury.ac.nz/study/getting-started/admission-and-enrolment",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://www.canterbury.ac.nz/communities/alumni",
  },
  // {
  //   title: "Accommodation",
  //   img: k5, // Replace with actual image variable if needed
  //   url: "https://www.canterbury.ac.nz/life/accommodation",
  // },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.canterbury.ac.nz/content/dam/uoc-main-site/documents/pdfs/d-other/Careers-graduate-destination-survey-2000-uc.pdf",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://www.canterbury.ac.nz/content/dam/uoc-main-site/documents/pdfs/c-brochures/international-students/international-prospectus-uc.pdf.coredownload.pdf",
  },
];

const OtagoKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://www.otago.ac.nz/unicol/student-life",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://www.otago.ac.nz/international/future-students/how-to-apply",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://www.otago.ac.nz/alumni",
  },
  // {
  //   title: "Accommodation",
  //   img: k5, // Replace with actual image variable if needed
  //   url: "https://www.otago.ac.nz/accommodation",
  // },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.otago.ac.nz/about/quickstats",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://www.otago.ac.nz/international/international-prospectus-and-publications",
  },
];

const AUTKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://www.aut.ac.nz/student-life",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://www.aut.ac.nz/?gad_source=1&gclid=Cj0KCQiAgdC6BhCgARIsAPWNWH0gRPqugxRYZifjCT-XbfxCql_9l2hZ_KbOtxWXtDosk_55eLKFb5waAsiVEALw_wcB",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://www.aut.ac.nz/alumni",
  },
  // {
  //   title: "Accommodation",
  //   img: k5, // Replace with actual image variable if needed
  //   url: "https://www.aut.ac.nz/student-life/accommodation",
  // },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.aut.ac.nz/about/auts-leadership/fact-and-figures-about-aut",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://www.aut.ac.nz/__data/assets/pdf_file/0006/768831/AUT-prospectus-2024-digital.pdf",
  },
];

const LincolnKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://www.lincoln.ac.nz/life-at-lincoln/",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://www.lincoln.ac.nz/study/apply-and-enrol/apply/",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://www.lincoln.ac.nz/alumni/",
  },
  // {
  //   title: "Accommodation",
  //   img: k5, // Replace with actual image variable if needed
  //   url: "https://www.lincoln.ac.nz/life-at-lincoln/accommodation/",
  // },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.lincoln.ac.nz/news-and-events/te-whare-wanaka-o-aoraki/",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://www.lincoln.ac.nz/about-us/brochures-and-publications/",
  },
];

const EITKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://www.eit.ac.nz/campus/international-students/life-in-auckland/",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://www.eit.ac.nz/apply-online-international/",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://www.eit.ac.nz/students/alumni/",
  },
  // {
  //   title: "Accommodation",
  //   img: k5, // Replace with actual image variable if needed
  //   url: "https://www.eit.ac.nz/campus/international-students/accommodation/",
  // },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.eit.ac.nz/wp-content/uploads/2021/11/EIT_Graduate-Outcome-Report-2021-002.pdf",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://www.eit.ac.nz/wp-content/uploads/2023/09/Prospectus-2023-EIT-Te-Pukenga-WEB-NEW-HB.pdf",
  },
];

const UCICKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://www.ucic.ac.nz/student-life-services/facilities/campus/",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://www.ucic.ac.nz/how-apply/",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://www.ucic.ac.nz/student-life-services/student-experience/ucsa-clubs/",
  },
  // {
  //   title: "Accommodation",
  //   img: k5, // Replace with actual image variable if needed
  //   url: "https://www.ucic.ac.nz/student-life-services/support/accommodation/",
  // },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.ucic.ac.nz/student-life-services/graduation/careers/",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://www.ucic.ac.nz/wp-content/uploads/UC-International-College-Self-Review-2023.pdf",
  },
];



 //Singapur

 const NUSKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://uci.nus.edu.sg/campus-life/",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://scale.nus.edu.sg/programmes/undergraduate-programmes/how-to-apply",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://law.nus.edu.sg/alumni/stay-connected/alumni-directory/",
  },
  {
    title: "Accommodation",
    img: k5, // Replace with actual image variable if needed
    url: "https://osa.nus.edu.sg/accommodation/",
  },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.iss.nus.edu.sg/collaboration/internship-and-placements",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://www.nus.edu.sg/docs/default-source/corporate-files/about/welcome-to-nus.pdf",
  },
];

const NTUKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://www.ntu.edu.sg/life-at-ntu#:~:text=Living%20on%20campus,trainings%2C%20retreats%20and%20private%20events.",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://www.ntu.edu.sg/engineering/admissions/ug/apply",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://www.ntu.edu.sg/alumni",
  },
  {
    title: "Accommodation",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.ntu.edu.sg/life-at-ntu/accommodation",
  },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://www3.ntu.edu.sg/CorpComms2/Documents/2019/02_Feb/Web%20Publication%20NTU%20GES%202018%20-%20Final.pdf",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://www.ntu.edu.sg/admissions/undergraduate/brochures",
  },
];

const SUTDKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://www.sutd.edu.sg/Campus-Life",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://www.sutd.edu.sg/Admissions/Undergraduate/Application/Application-Guide",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://istd.sutd.edu.sg/testimonials/alumni/",
  },
  {
    title: "Accommodation",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.sutd.edu.sg/Campus-Life/Housing",
  },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.sutd.edu.sg/Admissions/Undergraduate/Graduate-Employment-Results/2022#:~:text=The%202022%20Graduate%20Employment%20Survey,months%20of%20their%20final%20examinations.",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://www.sutd.edu.sg/About/publications/Brochures",
  },
];

const SMUKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://www.smu.edu.sg/campus-life",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://admissions.smu.edu.sg/faqs/admissions-and-applications",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://alumni.smu.edu.sg/",
  },
  {
    title: "Accommodation",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.smu.edu.sg/campus-life/visiting-smu/accommodation",
  },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://masters.smu.edu.sg/employment-report",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://admissions.smu.edu.sg/sites/admissions.smu.edu.sg/files/international-students/brochure%202023/SMU%20International%20Prospectus%202023.pdf",
  },
];

const JCUNowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://www.jcu.edu.au/international-students/life-at-jcu",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://www.jcu.edu.au/applying-to-jcu",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://www.jcu.edu.au/alumni",
  },
  {
    title: "Accommodation",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.jcu.edu.au/accommodation",
  },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.jcu.edu.au/placements/placements-and-projects#:~:text=We%20support%20over%205000%20student,Projects",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://www.jcu.edu.au/__data/assets/pdf_file/0019/104644/jcu_122163.pdf",
  },
];

const MDISKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://www.mdis.edu.sg/student-life",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://www.mdis.uz/en/menu/online-registration-",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://www.mdis.uz/en/menu/online-registration-",
  },
  {
    title: "Accommodation",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.mdis.edu.sg/mdis-residences-stirling",
  },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.mdis.edu.sg/graduate-employability",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://www.mdis.edu.sg/?utm_source=google&utm_medium=cpc&utm_campaign=pmax&gad_source=1",
  },
];

const SIMKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://www.sim.edu.sg/degrees-diplomas/admissions/international-students-onboarding/life-in-singapore",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://www.sim.edu.sg/degrees-diplomas/admissions/application-process",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://www.sim.edu.sg/alumni",
  },
  {
    title: "Accommodation",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.sim.edu.sg/degrees-diplomas/admissions/international-students-onboarding/accommodation",
  },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.sim.edu.sg/degrees-diplomas/sim-global-education/sim-advantage#:~:text=SIM%20graduates%20enjoy%20excellent%20career,employment%20rate%20of%2085.9%25*.&text=*%20Based%20on%20the%20SIM%20Graduate,months%20after%20completing%20their%20studies.",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://www.sim.edu.sg/degrees-diplomas/programmes/brochures-prospectus",
  },
];

const RafflesKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://raffles-college.edu.sg/",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://raffles-college.edu.sg/admissions-policies/",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://raffles-college.edu.sg/success-stories/",
  },
  {
    title: "Accommodation",
    img: k5, // Replace with actual image variable if needed
    url: "https://raffles-college.edu.sg/staying-and-studying-in-singapore/",
  },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://raffles.education/Mediaroom.html",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://staging.raffles.education/wp-content/uploads/2023/10/Corporate-Brochure.pdf",
  },
];

const ERAUAsiaKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://asia.erau.edu/student-life#:~:text=During%20the%20year%2C%20SGA%20organizes,Association%20and%20Women%20in%20Aviation.",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://asia.erau.edu/admissions",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://asia.erau.edu/career-outcomes/alumni-relations",
  },
  {
    title: "Accommodation",
    img: k5, // Replace with actual image variable if needed
    url: "https://daytonabeach.erau.edu/campus-life/housing",
  },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://news.erau.edu/media-resources/facts-and-figures/placement",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://asia.erau.edu/-/media/files/asia-files/brochure/erau-asia-undergraduate-international-brochure.pdf",
  },
];

const CurtinSingaporeKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://curtin.edu.sg/curtin-university/campus-facilities/#:~:text=Curtin%20Singapore%20provides%20the%20latest,tour%20around%20our%20campus%20here.",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://curtin.edu.sg/how-to-apply/",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://students.curtin.edu.sg/curtin-experience/career-development/alumni-relations/",
  },
  {
    title: "Accommodation",
    img: k5, // Replace with actual image variable if needed
    url: "https://curtin.edu.sg/future-students/international-students/accommodation/",
  },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.curtin.edu.au/news/media-release/curtin-singapore-ranked-first-in-graduate-employment-survey/",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://curtin.edu.sg/wp-content/uploads/sites/3/2024/02/CUSG240108-1404-Trifold-Course-Guide_FAW_Web.pdf",
  },
];



//UAE


const CUDKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://www.cud.ac.ae/life-on-campus#:~:text=The%20avant%2Dgarde%20campus%20is,innovation%2C%20interpersonal%20and%20leadership%20skills.",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://www.cud.ac.ae/admissions",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://www.cud.ac.ae/community-outreach/alumni",
  },
  {
    title: "Accommodation",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.cud.ac.ae/life-on-campus/accommodation",
  },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.cud.ac.ae/student-affairs/industry-placement",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://www.cud.ac.ae/downloads",
  },
];

const AjmanKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://www.ajman.ac.ae/en/campus-life",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://www.ajman.ac.ae/en/admission-and-registration/undergraduate/application-process",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://alumni.ajman.ac.ae/",
  },
  {
    title: "Accommodation",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.ajman.ac.ae/en/student-life/residence-life-and-housing/rooms-and-fees",
  },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.ajman.ac.ae/upload/files/oipe/Excerpt_of_AU_Fact_Book_2021-2022.pdf",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://www.ajman.ac.ae/upload/docs/AU_University_Catalogue_English_4-11-2017.pdf",
  },
];

const AbuDhabiKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://www.adu.ac.ae/life/life-at-adu/student-life-at-adu",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://www.adu.ac.ae/life/life-at-adu/student-life-at-adu",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://www.adu.ac.ae/study/financials/scholarships/alumni-scholarship",
  },
  {
    title: "Accommodation",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.adu.ac.ae/life/life-at-adu/residence-life-and-student-housing",
  },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.adu.ac.ae/news-and-events/news/news/detail/2022/12/08/graduates-of-abu-dhabi-university-rated-most-employable-in-the-nation",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://www.adu.ac.ae/life/life-at-adu/student-handbook",
  },
];

const AURAKKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://aurak.ac.ae/life-at-aurak/student-life",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://apply.aurak.ac.ae/Account/Login/?ReturnUrl=%2F",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://aurak.ac.ae/my-aurak/alumni/alumni-benefits",
  },
  {
    title: "Accommodation",
    img: k5, // Replace with actual image variable if needed
    url: "https://aurak.ac.ae/life-at-aurak/student-life/residential-halls",
  },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://aurak.ac.ae/about/about-aurak/aurak-at-a-glance",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://aurak.ac.ae/files/aurak/Brochures/E%20Brochure/SOB%20Graduate%20-%20Brochure.pdf",
  },
];

const UAEUKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://www.uaeu.ac.ae/en/eservices/",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://www.uaeu.ac.ae/en/eservices/",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://www.uaeu.ac.ae/en/eservices/",
  },
  {
    title: "Accommodation",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.uaeu.ac.ae/en/campus_life/residential/",
  },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.uaeu.ac.ae/en/dvcsae/student_career/",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://www.uaeu.ac.ae/en/vc/uod/publications/",
  },
];

const KhalifaKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://www.ku.ac.ae/student-life/campus-life#:~:text=Campus%20Life%20offers%20a%20broad,health%20campaigns%20and%20fitness%20classes.",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://www.ku.ac.ae/apply",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://www.ku.ac.ae/tag/alumni/",
  },
  {
    title: "Accommodation",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.ku.ac.ae/campus/student-housing#:~:text=Student%20housing%20is%20available%20at,cultures%2C%20customs%2C%20and%20traditions.",
  },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.ku.ac.ae/student-life/career-and-alumni-services-old",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://www.ku.ac.ae/brochures",
  },
];

const AUSKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://www.aus.edu/campus-life",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://www.aus.edu/admissions",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://www.aus.edu/alumni",
  },
  {
    title: "Accommodation",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.aus.edu/student-affairs/accommodation",
  },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.aus.edu/student-affairs/career-services",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://www.aus.edu/downloads/brochures",
  },
];

const AlAinKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://aau.ac.ae/en/campuses",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://aau.ac.ae/en/online-admission",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://alumni.aau.ac.ae/en",
  },
  {
    title: "Accommodation",
    img: k5, // Replace with actual image variable if needed
    url: "https://students.aau.ac.ae/en/services/facilities",
  },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://aau.ac.ae/en/facts-and-figures",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://aau.ac.ae/publications/brochures/al_ain_university_aau_brochure____en.pdf",
  },
];

const ZayedUniversityKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://www.zu.ac.ae/main/en/careers/working/index",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://apply.zu.ac.ae/Ellucian.ERecruiting.Web.External/Pages/Login.aspx?ReturnUrl=%2fEllucian.ERecruiting.Web.External%2fPages%2fapplicationlist.aspx&NeedsLogin=True",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://www.zu.ac.ae/main/en/alumni/index",
  },
  {
    title: "Accommodation",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.zu.ac.ae/main/en/international-students-residence/index",
  },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://www.zu.ac.ae/main/en/colleges/colleges/__college_of_humanities_and_social_sciences/_link/auh_internship",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://www.zu.ac.ae/main/en/gsd/connect-with-us/brochures",
  },
];

const UniversityOfDubaiKnowMore = [
  {
    title: "Campus Life",
    img: k1, // Replace with actual image variable if needed
    url: "https://ud.ac.ae/campus-life/",
  },
  {
    title: "How to Apply",
    img: k2, // Replace with actual image variable if needed
    url: "https://ud.ac.ae/apply/",
  },
  {
    title: "Alumni",
    img: k3, // Replace with actual image variable if needed
    url: "https://ud.ac.ae/alumni/",
  },
  {
    title: "Accommodation",
    img: k5, // Replace with actual image variable if needed
    url: "https://ud.ac.ae/admissions/international-students/accommodation/",
  },
  {
    title: "Placements Statistics",
    img: k5, // Replace with actual image variable if needed
    url: "https://ud.ac.ae/internship-and-career-center/",
  },
  {
    title: "Brochure",
    img: k6, // Replace with actual image variable if needed
    url: "https://ud.ac.ae/pdf/students/Graduate%20Catalog%202021-2022v.1.pdf",
  },
];




export {
  MelbourneKnowMore,
  CaliforniaKnowMore,
  WisconsinKnowMore,
  YeshivaKnowMore,
  UtahKnowMore,
  ColoradoKnowMore,
  IllinoisKnowMore,
  StanfordKnowMore,
  PennsylvaniaKnowMore,
  ChicagoKnowMore,
  MITKnowMore,
  BristolKnowMore,
  LeedsKnowMore,
  GlasgowKnowMore,
  BirminghamKnowMore,
  ImperialKnowMore,
  QueenMaryKnowMore,
  LiverpoolKnowMore,
  EdinburghKnowMore,
  SouthamptonKnowMore,
  DurhamKnowMore,
  UNSWKnowMore,
  MonashKnowMore,
  QueenslandKnowMore,
  WesternAustraliaKnowMore,
  AdelaideKnowMore,
  MacquarieKnowMore,
  UTSKnowMore,
  ANUKnowMore,
  SydneyKnowMore,
  TrentKnowMore,
  TrinityKnowMore,
  WindsorKnowMore,
  DalhousieKnowMore,
  CalgaryKnowMore,
  VCCKnowMore,
  TorontoKnowMore,
  RoyalRoadsKnowMore,
  WaterlooKnowMore,
  BrockKnowMore,

  //Germany
  LMUKnowMore,
  TUMKnowMore,
  HeidelbergUniversityKnowMore,
  HumboldtUniversityKnowMore,
  FUBerlinKnowMore,
  RWTHAachenKnowMore,
  UOFKnowMore,
  UOTKnowMore,
  UOMKnowMore,
  UOGKnowMore,
  
  //France

  UPSLKnowMore,
  UPSKnowMore,
  SUKnowMore,
  UGAKnowMore,
  UDSKnowMore,
  UDMKnowMore,
  UDLKnowMore,
  UDNKnowMore,
  UDBKnowMore,
  UPPSKnowMore,

  //Italy

  PDMilanoKnowMore,
  UOMilanKnowMore,
  UOBolognaKnowMore,
  UOFlorenceKnowMore,
  UOPaduaKnowMore,
  UOTurinKnowMore,
  RLSKnowMore,
  UONaplesKnowMore,
  UOPisaKnowMore,
  IEDKnowMore,

  //Ireland
  TCDublinKnowMore,
  UCDublinKnowMore,
  UOCorkKnowMore,
  UOGalwayKnowMore,
  DCUKnowMore,
  UOLimerickKnowMore,
  MUKnowMore,
  TUDublinKnowMore,
  DBSKnowMore,
  RCSIKnowMore,

  //New-Zealand
  AucklandKnowMore,
  MasseyKnowMore,
  WaikatoKnowMore,
  VictoriaKnowMore,
  CanterburyKnowMore,
  OtagoKnowMore,
  AUTKnowMore,
  LincolnKnowMore,
  EITKnowMore,
  UCICKnowMore,

  // singapur
// Know more

NUSKnowMore,
NTUKnowMore,
SUTDKnowMore,
SMUKnowMore,
JCUNowMore,
MDISKnowMore,
SIMKnowMore,
RafflesKnowMore,
ERAUAsiaKnowMore,
CurtinSingaporeKnowMore,


 // UAE
  CUDKnowMore,
  AjmanKnowMore,
  AbuDhabiKnowMore,
  AURAKKnowMore,
  UAEUKnowMore,
  KhalifaKnowMore,
  AUSKnowMore,
  AlAinKnowMore,
  ZayedUniversityKnowMore,
  UniversityOfDubaiKnowMore,

};
