const imageBaseUrl = process.env.REACT_APP_IMAGE_URL

const college1 = imageBaseUrl + "college1.png";
const college2 = imageBaseUrl + "college2.png";
const college3 = imageBaseUrl + "college3.png";
const college4 = imageBaseUrl + "college4.png";
const college5 = imageBaseUrl + "college5.png";
const college6 = imageBaseUrl + "college6.png";
const college7 = imageBaseUrl + "college7.png";
const college8 = imageBaseUrl + "college8.png";
const college9 = imageBaseUrl + "college9.png";
const college10 = imageBaseUrl + "college10.png";

const collegeData = [
  {
    title: "AIIMS",
    items: [

      "All India Institute of Medical Sciences (AIIMS), New Delhi",
    ],
    Image: college1,
  },
  {
    title: "CMC",
    items: ["Christian Medical College (CMC), Vellore"],
    Image: college2,
  },
  {
    title: "AFMC",
    items: ["Armed Forces Medical College (AFMC), Pune"],
    Image: college3,
  },
  {
    title: "MAMC",
    items: ["Maulana Azad Medical College (MAMC), New Delhi"],
    Image: college4,
  },
  {
    title: "GMC",
    items: ["Grant Medical College (GMC), Mumbai"],
    Image: college5,
  },
  {
    title: "KGMU",
    items: [


      "King George's Medical University (KGMU), Lucknow",
    ],
    Image: college6,
  },
  {
    title: "JIPMER",
    items: [


      "Jawaharlal Institute of Postgraduate Medical Education and Research (JIPMER), Puducherry",
    ],
    Image: college7,
  },
  {
    title: "LHMC",
    items: ["Lady Hardinge Medical College (LHMC), New Delhi"],
    Image: college8,
  },
  {
    title: "SGSMC",
    items: ["Seth GS Medical College (SGSMC), Mumbai"],
    Image: college9,
  },
  {
    title: "BMCRI",
    items: [

      "Bangalore Medical College and Research Institute (BMCRI), Bangalore",
    ],
    Image: college10,
  },
];

export default collegeData;
